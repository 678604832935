import React from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './pages';
import Blogs from './pages/blogs';
import Links from './pages/links';
  
function App() {
return (
    <Router>
        <Navbar />
        <Routes>
            <Route exact path='/' element={<Home />} />
            <Route path='/blogs' element={<Blogs/>} />
            <Route path='/links' element={<Links/>} />
        </Routes>
    </Router>
);
}
  
export default App;


