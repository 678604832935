import React from 'react';
import axios from 'axios';

const Cite = require('citation-js')

const Links = () => {
	const [tags, setTags] = React.useState([])
	const [tag, setTag] = React.useState(undefined);
	const [links, setLinks] = React.useState([]);
	const [references, setReferences] = React.useState("");

	React.useEffect(() => {
		axios.get('/bookmarks.json')
		.then(function (response) {
		  // handle success
		  console.log(response.data);
		  setTags([...new Set(response.data.map(i => i.tags).flat())].filter(Boolean).sort());
		  setLinks(response.data.filter(object => {return object.link && (tag === undefined || (object.tags && object.tags.includes(tag)))}).sort( (a,b) => {return a.title.localeCompare(b.title)}))
		})
		.catch(function (error) {
		  // handle error
		  console.log(error);
		})
	}, [tag]);

	React.useEffect(() => {
		axios.get('references.bib')
		.then(function (response) {
		  // handle success
		  console.log(response.data);
		  const data = Cite(response.data, {});
		  let output = data.format('bibliography', {format: 'html', template: 'apa'});
		  console.log(output);
		  setReferences(output);
		})
		.catch(function (error) {
		  // handle error
		  console.log(error);
		})
	}, []);

	return (
		<>
		<Tags tags={tags} setTag={setTag} />
		<hr/>
		<Link links={links} />
		<hr/>
		 {<div dangerouslySetInnerHTML={{ __html: references}}/>}
		</>
	)
};

export default Links;

function handleTag(event, tag, setTag) {
	event.preventDefault();
	console.log(tag);
	setTag(tag);
}

function Tags(props) {
	return (
	<>
	  <b>Tags: </b> 
	  <a href='#none' onClick={event => handleTag(event, undefined, props.setTag)}>#None</a>
	  {props.tags.map((key, i) => [
	   	", ",
		<a href='#{key}' onClick={event => handleTag(event, key, props.setTag)}>#{key}</a>
	  ])}
	</>
	)
}

function Link(props) {
	return (
	  <ul>
		{props.links.map((link) =>
		  <li key={link.link}>
			<a href={link.link} rel="noreferrer" target="_blank">{link.title}</a>
			{link.author && 
				<> - {link.author}</>
			}
			{link.date &&
				<>, {link.date}</>
			}
			{link.github && 
				 <small> [<a href={link.github} rel="noreferrer" target="_blank">GitHub</a>]</small>
			}
			{link.docs && 
				 <small> [<a href={link.docs} rel="noreferrer" target="_blank">Docs</a>]</small>
			}
		  </li>
		)}
	  </ul>
	);
  }
  