import React from "react";
import { Nav, NavLink, NavMenu } from "./NavbarElements";
import { AiOutlineHome } from 'react-icons/ai';

const Navbar = () => {
return (
	<>
	<Nav>
		<NavMenu>
			<NavLink to="/" activeStyle><AiOutlineHome/></NavLink>
			<NavLink to="/blogs" activeStyle>Blogs</NavLink>
			<NavLink to="/links" activeStyle>Links</NavLink>
		</NavMenu>
	</Nav>
	</>
);
};

export default Navbar;