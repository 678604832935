import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
background: #93d4ed;
height: 30px;
display: flex;
justify-content: space-between;
align-items: center;
z-index: 12;
`;

export const NavLink = styled(Link)`
color: #0a0000;
display: flex;
align-items: center;
text-decoration: none;
padding: 0 1rem;
height: 100%;
cursor: pointer;
&.active {
	color: #030357;
}
`;

export const NavMenu = styled.div`
display: flex;
align-items: center;
margin-right: -24px;
`;
